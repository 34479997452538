
.window-container {
    width: 100%; 
    padding: 5rem; 
    font-size: 1rem; 
    line-height: 1.4rem; 
    color: white;
    background-color: rgba(25, 23, 23, 0.65); 
    border-radius: 5px;
    overflow-x: auto; 
    overflow-y: auto; 
    /* max-height: 72vh; */
    height: 85%;
    max-width: 140vh;
  }

  .subhead {
    text-align: center;
    color: #e56c61;
    font-size: 2.5rem;
  }
  

.schedule-container {
    width: 100%;  
    max-width: 1200px;  
    margin: 20px auto; 
    overflow-y: auto; 
}

table {
    width: 100%;  
    border-collapse: collapse;
    table-layout: fixed;  
}

table, th, td {
    border: 1px solid #ccc;
    text-align: center;
    padding: 8px;  
    font-size: 16px;  
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

thead th {
    background-color: #e56c61;
    color: white;
}

@media screen and (max-width: 768px) {

    .window-container {
        padding: 0.5rem;
        font-size: 1.2rem; 
      }
    
    .schedule-container {
        max-width: 100%;  
        padding: 1rem;
    }

    th, td {
        padding: 8px 4px;
        font-size: 14px;
    }
}

@media screen and (max-width: 480px) {
    th, td {
        font-size: 12px;
        padding: 4px;
    }
}
