/* Start Page */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.mainHome{
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    color: #fff;
  }

  
  .title {
    font-size: 5rem;
    letter-spacing: 0.5rem;
    text-align: center;
    /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
    font-family: "Press Start 2P", system-ui;
    font-weight: 400;
    font-style: normal;
    text-shadow: 0 0 10px #d5665b,
                0 0 0px #d5665b,
                0 0 15px #d5665b;
  }
  
/* .glitch {
    position: relative;
    color: white;
    font-size: 4rem;
    letter-spacing: 0.5rem;
    text-align: center;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    letter-spacing: 0.5em;
    animation: glitch-skew 3s infinite linear alternate-reverse;
}
.glitch::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: 2px;
  text-shadow: -2px 0 #ff00c1;
  clip: rect(44px, 450px, 56px, 0);
  animation: glitch-anim 5s infinite linear alternate-reverse;
}
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: -2px;
  text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
  animation: glitch-anim2 1s infinite linear alternate-reverse;
}

@keyframes glitch-anim {
  0% {
    clip: rect(9px, 9999px, 43px, 0);
    transform: skew(0.22deg);
  }
  5% {
    clip: rect(7px, 9999px, 6px, 0);
    transform: skew(0.02deg);
  }
  10% {
    clip: rect(99px, 9999px, 61px, 0);
    transform: skew(0.43deg);
  }
  15% {
    clip: rect(24px, 9999px, 49px, 0);
    transform: skew(0.5deg);
  }
  20% {
    clip: rect(31px, 9999px, 90px, 0);
    transform: skew(0.95deg);
  }
  25% {
    clip: rect(13px, 9999px, 86px, 0);
    transform: skew(0.39deg);
  }
  30% {
    clip: rect(100px, 9999px, 63px, 0);
    transform: skew(0.25deg);
  }
  35% {
    clip: rect(17px, 9999px, 70px, 0);
    transform: skew(0.95deg);
  }
  40% {
    clip: rect(76px, 9999px, 70px, 0);
    transform: skew(0.43deg);
  }
  45% {
    clip: rect(90px, 9999px, 3px, 0);
    transform: skew(0.84deg);
  }
  50% {
    clip: rect(42px, 9999px, 60px, 0);
    transform: skew(0.47deg);
  }
  55% {
    clip: rect(13px, 9999px, 33px, 0);
    transform: skew(0.12deg);
  }
  60% {
    clip: rect(3px, 9999px, 26px, 0);
    transform: skew(0.07deg);
  }
  65% {
    clip: rect(21px, 9999px, 64px, 0);
    transform: skew(0.95deg);
  }
  70% {
    clip: rect(80px, 9999px, 16px, 0);
    transform: skew(0.78deg);
  }
  75% {
    clip: rect(41px, 9999px, 47px, 0);
    transform: skew(0.88deg);
  }
  80% {
    clip: rect(45px, 9999px, 53px, 0);
    transform: skew(0.52deg);
  }
  85% {
    clip: rect(18px, 9999px, 53px, 0);
    transform: skew(0.88deg);
  }
  90% {
    clip: rect(92px, 9999px, 1px, 0);
    transform: skew(0.15deg);
  }
  95% {
    clip: rect(10px, 9999px, 60px, 0);
    transform: skew(0.83deg);
  }
  100% {
    clip: rect(74px, 9999px, 12px, 0);
    transform: skew(0.24deg);
  }
}
@keyframes glitch-anim2 {
  0% {
    clip: rect(20px, 9999px, 45px, 0);
    transform: skew(0.31deg);
  }
  5% {
    clip: rect(75px, 9999px, 11px, 0);
    transform: skew(0.12deg);
  }
  10% {
    clip: rect(97px, 9999px, 66px, 0);
    transform: skew(0.25deg);
  }
  15% {
    clip: rect(4px, 9999px, 69px, 0);
    transform: skew(0.54deg);
  }
  20% {
    clip: rect(42px, 9999px, 51px, 0);
    transform: skew(0.11deg);
  }
  25% {
    clip: rect(74px, 9999px, 74px, 0);
    transform: skew(0.5deg);
  }
  30% {
    clip: rect(27px, 9999px, 22px, 0);
    transform: skew(0.5deg);
  }
  35% {
    clip: rect(56px, 9999px, 80px, 0);
    transform: skew(0.34deg);
  }
  40% {
    clip: rect(97px, 9999px, 79px, 0);
    transform: skew(0.84deg);
  }
  45% {
    clip: rect(67px, 9999px, 15px, 0);
    transform: skew(0.86deg);
  }
  50% {
    clip: rect(51px, 9999px, 44px, 0);
    transform: skew(0.62deg);
  }
  55% {
    clip: rect(40px, 9999px, 81px, 0);
    transform: skew(0.24deg);
  }
  60% {
    clip: rect(11px, 9999px, 25px, 0);
    transform: skew(0.45deg);
  }
  65% {
    clip: rect(35px, 9999px, 82px, 0);
    transform: skew(0.48deg);
  }
  70% {
    clip: rect(62px, 9999px, 11px, 0);
    transform: skew(0.21deg);
  }
  75% {
    clip: rect(51px, 9999px, 30px, 0);
    transform: skew(0.31deg);
  }
  80% {
    clip: rect(24px, 9999px, 78px, 0);
    transform: skew(0.77deg);
  }
  85% {
    clip: rect(64px, 9999px, 10px, 0);
    transform: skew(0.26deg);
  }
  90% {
    clip: rect(89px, 9999px, 61px, 0);
    transform: skew(0.72deg);
  }
  95% {
    clip: rect(45px, 9999px, 35px, 0);
    transform: skew(0.99deg);
  }
  100% {
    clip: rect(68px, 9999px, 38px, 0);
    transform: skew(0.24deg);
  }
}
@keyframes glitch-skew {
  0% {
    transform: skew(4deg);
  }
  10% {
    transform: skew(4deg);
  }
  20% {
    transform: skew(-3deg);
  }
  30% {
    transform: skew(1deg);
  }
  40% {
    transform: skew(5deg);
  }
  50% {
    transform: skew(-3deg);
  }
  60% {
    transform: skew(2deg);
  }
  70% {
    transform: skew(-3deg);
  }
  80% {
    transform: skew(-2deg);
  }
  90% {
    transform: skew(5deg);
  }
  100% {
    transform: skew(1deg);
  }
} */


  .subtitle {
    font-size: 2rem;
    letter-spacing: 0.25rem;
    text-align: center;
    text-shadow: 0 0 10px #d5665b,
                0 0 40px #d5665b,
                0 0 80px #d5665b;

  }
  
  .subhead {
    color: #e56c61;
    text-align: center;
    margin: 2rem 0;
    font-size: 2.7rem;
  }
  
  .cta-btn {
    padding: 1rem 3rem;
    font-size: 1.75rem;
    background-color: transparent;
    color: #c3da4b;
    border: 0.2rem solid #fff;
    cursor: pointer;
    margin-top: 3rem;
    position: relative;
    background-blend-mode: difference;
    text-decoration: none;
  }
  
  .cta-btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.763);
    transition: width 0.1s linear;
    z-index: -1;
  }
  
  .cta-btn:hover {
    color: #000;
    font-weight: 600;
    box-shadow: 0 0 10px #d5665b,
                0 0 40px #d5665b,
                0 0 80px #d5665b;
    background-color: #d5665b;
  }
  
  .cta-btn:hover::before {
    width: 100%;
  }
  
  .window-main{
    display: flex;
    justify-content: center;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  }
  .window {
    margin-top: 3rem;
    width: 100%;
    height: 85%;
    max-width: 900px;
    border: 0.1rem solid #fff;
    color: #fff;
    background-color: rgba(25, 23, 23, 0.65);
    display: flex;
    justify-content: center;
    border-radius: 1rem;
  }

  .window-content {
    width: 100%;
    height: calc(100% - 4rem);
    padding: 2rem;
    font-size: 1.5rem;
    text-align: justify;
    overflow-y: auto  ;
    line-height: 2.5rem;
  }

  .window-content p{
    font-size: 1.5rem;
    border-bottom: .2rem dashed #e56c61;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    text-align: justify;
  }
  
  .vol-container {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    line-height: normal;
  }
  
  .team-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 3rem;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    padding: 2rem ;
}
  .card {
    width: 20rem;
    height: 22rem;
    flex-grow: 0;
    flex-shrink: 0;
    border: 0.15rem solid #e56c61;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    transition: transform 0.1s linear;
    user-select: none;

  }
  
  .card p {
    font-size: 1.5rem;
    text-align: center;
    border: none;
    padding: 0;
  }
  
  .card .team-img {
    border-radius: 100%;
    box-shadow: .15rem .15rem 1rem hsla(0,0%,100%,.3);
    height: 10rem;
    margin-bottom: 1rem;
    width: 10rem;
}
  .card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  
  .linkedin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(203, 203, 203);
    text-decoration: none;
    cursor: pointer;
    gap: 1.5rem;
  }

  .MuiSvgIcon-root{
    font-size: 2.5rem !important;
  }

  
  .card a {
    font-size: 2rem;
    color: #0072b1;
  }
  
  .role {
    color: rgba(228, 228, 228, 0.75);
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .avatar {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    margin-bottom: 2rem;
    box-shadow: 0.15rem 0.15rem 1rem rgba(255, 255, 255, 0.3);
    /* overflow: hidden; */
  }
  
  /* faq page */
  
  .faq-list {
    list-style-type: none;
  }
  
  .faq-container {
    margin: 1rem 0;
  }
  
  .faq-question {
    font-size: 1.65rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  /* .faq-answer {
    margin-left: 1rem;
  } */
  
  /* protocol */
  .section-text {
    color: #fffffffc;
  }
  
  /* events */
  .event {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    -webkit-user-drag: none;
  }
  
  .ascii {
    width: 50%;
    max-width: 100%;
    margin-bottom: 2rem;
  }
  
  .event p {
    text-align: center;
    line-height: 200%;
  }

  .getin-cont-h{
    position: absolute;
    left: 3rem;
    top: calc(80% - 20rem);
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .card-social-h{

  }
  .card-social-h {
    /* border: 0.2rem solid #fff;
    padding: 1.5rem 1.7rem;
    border-radius: 50%;
    margin-block: 1rem;
    cursor: pointer; */
  }

  .card-text-h{
    cursor: pointer;
  }

  /* .sociallink{
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 30px;
    bottom: 30px;
    align-items: center;
    margin: 0px auto;
} */

.sociallink ul li{
    padding: 0.5em 0.5em 0.3em 0.5em;
    margin: 0.5em;
    border: 1px solid rgb(30, 33, 34);
    border-radius: 50%;
    transition: 0.1s;
    cursor: pointer;
    list-style: none;
    gap: 1rem;
  }
  .sociallink ul li a{
    font-size: 1.5em;
    color: #ffffff;
    text-decoration: none;
  }

.sociallink ul li:hover{
    border: 1px solid rgb(255, 255, 255);
    transition: 0.1s;
}

@media only screen and (max-width:900px){

}
  
  @media screen and (max-width: 1500px) {
    .window {
      width: 105rem;
    }
  }

  @media screen and (max-width: 900px) {
    .getin-cont-h{
      top: calc(10% - 12rem) !important;
    }
  }
  
  @media screen and (max-width: 1080px) {
    .window {
      width: 80%;
    }

    .getin-cont-h{
      position: relative;
      display: flex;
      flex-direction: row;
      top: calc(10% - 12rem);
      left: calc(50% - 15rem);
      width: 50%;
    }
  }
  
  @media screen and (max-width: 500px) {
    h1.title {
      font-size: 2rem;
    }
    .subtitle {
      font-size: 1.25rem;
      padding-inline: 1rem;
    }
    .cta-btn {
      font-size: 1.5rem;
    }
    .window {
      width: 92%;
      height: 90%;
    }
    .window-title-bar {
      font-size: 1.5rem;
    }
    .window-content {
      font-size: 1.25rem;
    }
    .faq-question {
      font-size: 1.25rem;
    }
    .card {
      width: 22rem;
    }
    .ascii {
      width: 85%;
    }
    .event p {
      text-align: justify;
      line-height: 175%;
    }
  }
  
  .venuep {
    font-size: 2rem; 
    margin-top: 1rem;
  }
  
  .venuelink {
    color: white;
    font-weight: 800;
    text-decoration: underline;
  }
  
  @media (max-width: 1200px) {
    .venuep {
      font-size: 1.8rem; 
    }
  }
  
  @media (max-width: 768px) { 
    .venuep {
      font-size: 1.6rem; 
    }
  }
  
  @media (max-width: 480px) { 
    .venuep {
      font-size: 1.4rem;
    }
    
    .venuelink {
      font-size: 1.2rem; 
      text-decoration: underline;
    }
  }
  