.window-sp{
    margin-top: 4rem;
    width: 100%;
    height: 85%;
    max-width: calc(100% - 20rem);
    /* border: 0.1rem solid #fff; */
    color: #fff;
    background-color: rgba(25, 23, 23, 0.65);
    display: flex;
    justify-content: center;
    border-radius: 1rem;
  }

.first-sponser{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.first-sponser div{
    margin: 2rem;
}
.sponsor-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 4rem;
}

.sponsor-card{
    width: 20rem;
    height: 18rem;
    flex-grow: 0;
    flex-shrink: 0;
    /* border: 0.15rem solid #e56c61; */
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    transition: transform 0.1s linear;
    -webkit-user-select: none;
    user-select: none;
    background-color: rgba(25, 23, 23, 0.65);
}

.sponsor-img{
    width:80%;
    object-fit: cover;
}

.sponsorus{
    height: 10rem;
    width: 100%;
    margin-bottom: 1rem;
}

.sponsorus p{
    text-align: center;
}

@media screen and (max-width: 800px) {
    .window-sp{
        margin-top: 4rem;
        width: 100%;
        height: 85%;
        max-width: calc(100% - 4rem);
        /* border: 0.1rem solid #fff; */
        color: #fff;
        background-color: rgba(25, 23, 23, 0.65);
        display: flex;
        justify-content: center;
        border-radius: 1rem;
      }

    .sponsor-img{
        width:80%;
        object-fit: cover;
    }

    .sponsor-card{
        width: 16rem;
        height: 12rem;
    }
}
@media screen and (max-width: 500px) {
    .window-sp{
        margin-top: 4rem;
        width: 100%;
        height: 85%;
        max-width: calc(100% - 4rem);
        /* border: 0.1rem solid #fff; */
        color: #fff;
        background-color: rgba(25, 23, 23, 0.65);
        display: flex;
        justify-content: center;
        border-radius: 1rem;
      }

    .sponsor-img{
        width:70%;
        object-fit: cover;
    }

    .sponsorus{
        height: 10rem;
        width: 100%;
        margin-bottom: 7rem;
    }

    .sponsorus p{
        font-size: 1.5rem;
        justify-content: center;
    }

    .sponsor-card{
        width: 14rem;
        height: 10rem;
    }
}

