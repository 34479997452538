.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1a1e23;
  }

  header {
    width: 100%;
    margin: 0 1rem;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .header-logo {
    width: 10rem;
    object-fit: contain;
    cursor: pointer;
  }

#contactus  {
    text-decoration: none;
    border: .15rem solid silver;
    border-radius: .375rem;
    padding: .5rem 1rem;
    color:#fff;
    font-size: 2vmin;
    margin-right: 4rem;
}

/* #contactus:hover{
  color: #255784;
    background: #2196f3;
    box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
    transition-delay: 1s;
} */

#contactus:hover{
  box-shadow: 0 0 10px #d5665b,
                0 0 40px #d5665b,
                0 0 80px #d5665b;
    background-color: #d5665b;
}
  
  
  @media screen and (max-width: 500px) {
    .header-logo {
      width: 11rem;
    }

    #contactus  {
    text-decoration: none;
    border: .15rem solid silver;
    border-radius: .375rem;
    padding: .5rem 1rem;
    color:#fff;
    font-size: 3vmin;
    margin: 0;
}
  
  }
  