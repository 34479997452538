.countdown__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    width: 40%;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  @media screen and (max-width: 500px){
    .countdown__container{
        width:80%;
    }
  }
  
  .countdown__container .countdown__card {
    width: 18%;
  }
  
  .countdown__container .countdown__card .countdown__card__bg {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    background-color: #343650;
    -webkit-box-shadow: 0px 8px 0px 1px #191a24;
            box-shadow: 0px 8px 0px 1px #191a24;
    border-radius: 10px;
    -webkit-transition: all 0.175s linear;
    transition: all 0.175s linear;
  }
  
  .countdown__container .countdown__card .countdown__card__bg::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 1vh);
    width: 6px;
    height: 12px;
    border-radius: 0 15px 15px 0;
    background-color: #191a24;
    z-index: 10;
  }
  
  .countdown__container .countdown__card .countdown__card__bg::after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 1vh);
    width: 6px;
    height: 12px;
    border-radius: 15px 0 0 15px;
    background-color: #191a24;
    z-index: 10;
  }
  
  .countdown__container .countdown__card .countdown__card__bg .countdown__card__number {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    place-content: center;
    place-items: center;
    font-size: 3rem;
    color: #fb6087;
  }
  
  .countdown__container .countdown__card .countdown__card__bg .countdown__card__number::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    background-color: #1e1f29;
    opacity: 0.35;
    z-index: 10;
  }
  
  .countdown__container .countdown__card__label {
    padding-top: 1.5rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 3px;
    color: #8486a9;
    text-align:center;
  }
  
  .rotate {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
  }

  @media screen and (max-width: 850px) {
    .countdown__card__label{
      font-size: 1rem !important;
      letter-spacing: 2px !important;
    }
  }
  
  @media screen and (max-width: 500px) {
    .countdown__card__label{
      font-size: 1rem !important;
      letter-spacing: 2px !important;
    }
  }