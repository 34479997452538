.nav-container {
  position: fixed;
  width: 90%;  /* Adjusted to 90% for better responsiveness */
  max-width: 900px;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.2rem;
  animation: nav-full-load 1s 1;
  display: flex;
  border-top: none;
  z-index: 1000;  /* Ensure it's on top of other elements */
}

.nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  transform: translateY(0.1rem);
  color: #fff;
}

.nav-btn {
  flex: 1;  /* Makes buttons flexibly take up equal space */
  cursor: pointer;
  text-align: center;
}

.nav-btn.active {
  cursor: pointer;
  text-align: center;
}

.nav-btn-text {
  font-size: 1.5rem; /* Adjusted initial font size for desktop */
  transform: translateY(-0.25rem);
  animation: text-flow-up-load 1.5s 1;
  transition: text-shadow 0.2s linear;
  user-select: none;
  color: #fff;
  text-transform: uppercase;
}

.nav-btn > div {
  height: 0.65rem;
  background-color: #d5665b;
  transition: width 0.3s ease-in-out;
  position: relative;
  overflow: hidden; /* Added to control overflow */
}

.nav-btn > div::after {
  content: "";
  width: 100%;
  height: inherit;
  position: absolute;
  background-color: #d4ff00;
  right: 0;
  filter: blur(0.5rem);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; /* Smooth transition for the glow effect */
}

.active > div {
  width: 100%; /* Full bar width when active */
  height: 2px; /* Reduced height for the active state underline */
}

.active .nav-btn-text {
  color: #fff;
  font-weight: 800;
  text-shadow: 0 0 10px #d5665b, 0 0 20px #d5665b, 0 0 30px #d5665b;
  border: 0.1rem solid #fff;
  padding-block: 0.5rem;
  border-radius: 0.5rem;
}

@media screen and (max-width: 850px) {
  .nav-container {
      width: 95%; /* More width for smaller screens */
  }
  .nav-btn-text {
      font-size: 1.25rem; /* Slightly smaller font size */
  }
}

@media screen and (max-width: 500px) {
  .nav-container {
      width: 100%; /* Full width on very small screens */
  }
  .nav-btn-text {
      font-size: 1rem; /* Further reduction for very small devices */
      padding-inline: 0.5rem; /* Reduce padding to save space */
  }
  .nav-btn > div {
      height: 2px; /* Smaller active bar height */
  }
}
