.about-main{
    display: flex;
    justify-content: center;
    height: 94%;
    margin-top: 4rem;
}

.about-window{
    color:white;
    background-color: rgba(25, 23, 23, 0.65);
    /* border: .2rem solid #fff; */
    color: #fff;
    height: 80%;
    margin-top: 3rem;
    max-width: 1200px;
    border-radius:1rem;
}


.about-window h1{
    text-align: center;
    line-height: 5rem;
    /* border-bottom: .5rem solid #d5665b; */
    border-bottom:.2rem dashed #e56c61;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 2.7rem;
    margin-top: 2rem;
}

.about-content{
    display:flex;
    padding: 4rem 2rem;
    height: 90%;
}

.about-content img{
    object-fit: contain;
    filter: drop-shadow(2px 4px 6px black);
    width: 40%;
}

.about-content p{
    padding: 0 2rem;
    text-align: justify;
    /* word-spacing:-8px; */
    /* word-break: break-all; */
    font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
    font-weight: lighter;
    font-size: 1.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-content p span{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-bottom: 1rem;
    text-align: center;
    color: #e56c61;
    font-weight:bold;
}

.stat-container {
    align-items: center;
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
}

.stat {
    align-items: center;
    background-color: #14171b;
    color: #ececec;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 2rem !important;
    width: 50%;
    border-radius: 0.5rem;
}


.about .stat-value {
    font-size: 3em;
}

@media screen and (max-width: 768px) {

    .about-main{
        height:88%;
        margin-top:2rem;
    }
    .about-window{
        color:white;
        background-color:rgba(25, 23, 23, 0.65);
        /* border: .2rem solid #fff; */
        color: #fff;
        height: 94%;
        margin-top: 3rem;
        max-width: 900px;
        margin: 2rem;
        overflow-y: auto;
    }

    .about-window h1{
        margin-top: 0;
    }
    .about-content{
        display: flex;
        flex-direction: column;
        color: #fff;
        width: 100%;
        align-items: center;
        padding: 4rem 0;
    }   
    
    .about-content img{
        object-fit: contain;
        width: 70%;
    }

    .about-content p{
        font-size: 1.5rem;
        /* padding: 3rem 0 0 0; */
        padding:3rem;
    }

    .stat-container{
        justify-content: center;
    }

    .stat{
        padding: 1rem 2rem !important;
    }

    .stat-value{
        font-size: 2rem;
    }

    .stat-tag{
        /* width: 100%; */
        font-size: 1.5rem;
    }
}

.venuep {
    font-size: 2rem; 
    margin-top: 1rem;
  }
  
  .venuelink {
    color: white;
    font-weight: 800;
    text-decoration: underline;
  }
  
  @media (max-width: 1200px) {
    .venuep {
      font-size: 1.8rem; 
    }
  }
  
  @media (max-width: 768px) { 
    .venuep {
      font-size: 1.6rem; 
    }
  }
  
  @media (max-width: 480px) { 
    .venuep {
      font-size: 1.4rem;
    }
    
    .venuelink {
      font-size: 1.2rem; 
      text-decoration: underline;
    }
  }