.faq-container {
    width: 100%;
    padding: 2rem 10rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
    overflow-y: auto;
  }
  
  .faq-question {
    width: 100%;
    margin: 1rem 0;
    background-color: #e56c61;
    color: white;
    text-align:center;
    padding: 1rem;
    font-size: 1.7rem;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
  }
  
  .faq-question:hover {
    background-color: #d5665b;
  }
  
  /* .faq-question::after {
    content: "▼"; 
    font-size: 1.5rem;
    transition: transform 0.3s ease;
  }
  
  .faq-active .faq-question::after {
    transform: rotate(180deg); 
  } */
  
  .highlight {
    color: #e56c61; 
  }
  
  .faq-answer {
    display: none;
    padding: 1rem;
    border: 1px solid #e56c61;
    border-top: none;
    font-size: 1.5rem;
    line-height: 2.5rem;
    text-align: justify;
    color: white; 
    background-color: rgba(51, 51, 51, 0.5); ; 
  }
  
  .faq-answer p{
    padding:1rem;
    display: flex;
    justify-content: center;
  }

  .faq-answer ul {
    padding-left: 0;
    list-style-position: inside;
  }
  
 
  .faq-answer li {
    color: #ffffff; 
    margin-left: 1rem; 
  }
  

  .faq-answer li .highlight {
    color: #e56c61; 
  }
  
  .faq-active .faq-answer {
    display: block;
  }
  
  @media screen and (max-width: 760px) {
    .faq-container {
      padding: 1.5rem;
    }
  
    .faq-question {
      font-size: 1.5rem;
      padding: 0.8rem;
    }
  
    .faq-answer {
      font-size: 1.3rem;
      padding: 0.8rem;
    }
  
    
    .faq-answer ul {
      padding-left: 15px; 
    }
  }
  
  
  