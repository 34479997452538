/* .back{
  background-image: url(./components/images/back.jpg);
} */

.main{
    /* background-blend-mode: color-dodge; */
    background-repeat: no-repeat;
    display: grid;
    grid-template-rows: 7.5% 85% 7.5%;
    height: 100vh;
    /* padding: 1rem; */
    position: relative;
    width: 100%;
}

.main::before {
    background-image: url(./components/images/back.png);
    object-fit: cover;
    background-size: cover;
    content: "";
    /* -webkit-filter: blur(.1rem);
    filter: blur(.1rem); */
    height: 100vh;
    left: 0;
    /* opacity: .25; */
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

@media screen and (max-width: 768px) {
  .main::before{
    background-image: url(./components/images/Android_back.png);
  }
}

.particles-bg-canvas-self {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #363434;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 500px) {
    ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
  background: #999393;
  border-radius: 10px;
}
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
