.address{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}

.addr-line{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: 400;
    font-family: 'Inter';
}

.addr-map{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.addr-map img{
    width: 35rem;
    height: 35rem;
}

.addr-map iframe{
    width: 55rem;
    height: 35rem;
    border: 0;
}

@media screen and (max-width: 700px) {
    .addr-map iframe{
        width: 30rem;
        height: 40rem;
    }

    .addr-map img{
    width: 30rem;
    height: 30rem;
    }
}