@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.speaker-content {
    width: 100%;
    height: calc(100% - 4rem);
    padding: 2rem 4rem;
    font-size: 1.5rem;
    text-align: justify;
    overflow-y: auto  ;
    line-height: 2.5rem;
  }

  .speaker-content h1 {
    color: #e56c61;
    text-align: center;
    margin: 1rem 0 3rem 0;
    font-size: 2.7rem;
    line-height: 3rem;
}

  .speaker-content p{
    font-size: 1.5rem;
    border-top: .2rem dashed #e56c61;
    padding : 2rem 0;
    font-size: 1.6rem;
    /* text-align: justify; */
  }

  .speaker-content .info{
    display: flex;
    flex-wrap: wrap;
    padding: 4rem 8rem;
  }

  .speaker-content .email{
    text-decoration: noen;
    color: #d5665b;
  }

  .speaker-content .card2{
    margin: 2rem 2rem 3rem 2rem;
    width: 90%;
    border: 1px solid #e56c61;
  }

  .speaker-content .card-main{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }

  .speaker-content .card3{
    /* margin: 2rem 2rem 3rem 2rem; */
    width: 45%;
    border: 1px solid #e56c61;
  }

  .speaker-content .card3-big-main{
    border: 1px solid #e56c61;
  }

  .card3-big{
    display: flex;
    flex-wrap: wrap;
  }

  .card3-big .info{
    display: flex;
    flex-wrap: wrap;
    padding: 0rem 8rem;
  }

  .speaker-content .card2-head{
    width: 100%;
    margin: 0;
    background-color: #e56c61;
    text-align: center;
    padding: 1rem;
  }

  .speaker-content .card2-info{
    padding: 2rem;
  }

  .speaker-content .card2-info .det{
    display: flex;
  }
  .speaker-content .card2-info .det .date{
    display: flex;
    justify-content: center;
  }
  .speaker-content .card2-info .det div{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid #e56c61;
    margin-top: 0.8rem;
    padding: 0.5rem;
  }

  .speaker-content .card2-info .det-bef{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid #e56c61;
    margin-top: 0.5rem;
    padding: 0.5rem;
  }
  .speaker-content .card2-info .det div p , .speaker-content .card2-info .det-bef p{
    border: none;
    text-align: center;
    padding: 0.5rem;
  }


  .cfp-main{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-block: 2rem;
  }

  .cfp-btn  {
    text-decoration: none !important;
    border: .15rem solid silver;
    border-radius: .375rem;
    padding: .5rem 1rem;
    color:#e56c61 !important;
    font-size: 2.2vmin !important;
    margin-right: 4rem !important;
    box-shadow: rgba(247, 246, 246, 0.19) 0px 10px 20px, rgba(255, 255, 255, 0.23) 0px 6px 6px;
    background-color: aliceblue;
}

.cfp-btn:hover{
    box-shadow: 0 0 10px #d5665b,
                0 0 40px #d5665b,
                0 0 80px #d5665b;
    background-color: #d5665b;
    color: aliceblue !important;
}

@media screen and (max-width: 760px){
  
  .speaker-content {
    padding: 0;
  }
    .speaker-content h1 {
        color: #e56c61;
        text-align: center;
        margin: 1rem 0 3rem 0;
        font-size: 2.4rem;
    }

    .cfp-btn {
    text-decoration: none !important;
    border: .15rem solid silver;
    border-radius: .375rem;
    padding: .5rem 1rem;
    color: #e56c61 !important;
    font-size: 3vmin !important;
    margin: 0 !important;
}

.speaker-content .info{
    margin-left: 3rem;
    text-align: left;
    padding: 1.5rem;
  }

  .speaker-content .card3{
    margin: 2rem 2rem 3rem 2rem;
    width: 100%;
    border: 1px solid #e56c61;
  }
}


/* speaker cards goes here */
.speaker-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 3rem;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 2rem;
}

.speaker-card-main {
  width: 25rem;
  min-height: 30rem;
  border-radius: 2rem;
  border: 1px #4f4f4f solid;
  background-color: #232323;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;
}

.speaker-card-main:hover{
  border: 1px #e56c61 solid;
  transition: all 0.5s;
}

.speaker-card-main.flipped{
  transform: rotateY(180deg); 
}

.speaker-card-main .speaker-card-header{
  margin-top: 3rem;
  /* margin-left: 2rem; */
  height: 8rem;
}

.speaker-card-main .speaker-card-header h1 {
  color: #fff;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 2rem;
  line-height: 2.5rem;
}

.speaker-card-main .speaker-card-header p {
  color: #e56c61;
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.6rem;
  border-top: none; /* Ensure no border is shown */
  padding: 0;
}


.speaker-img-con {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
}


.speaker-img img {
  border-radius: 100%;
  box-shadow: .15rem .15rem 1rem hsla(0,0%,100%,.3);
  height: 11rem;
  width: 11rem; 
  filter: drop-shadow(-10px -20px 40px #e56c61);
}

.speaker-card-main img{
    /* box-shadow: .15rem .15rem 1rem hsla(0,0%,100%,.3); */
    /* height: 19rem;
    width: 16rem;
    border-bottom-right-radius: 2rem;
    position: relative;
    bottom: 0; */
    filter: drop-shadow(-10px -20px 40px #e56c61);
      border-radius: 100%;
      box-shadow: .15rem .15rem 1rem hsla(0,0%,100%,.3);
      height: 10rem;
      margin-bottom: 0rem;
      width: 10rem;
  }

  .speaker-con {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column; /* Align children elements vertically */
    align-items: center;
    padding: 0.5rem 0;
    margin-top: 0.5rem; 
}

.speaker-con .speaker-con-btn {
  background-color: #353535;
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  display: flex;
  width: fit-content;
  align-self: center;
}


.speaker-con .speaker-con-btn:hover {
  background-color: #d5665b;
  box-shadow: 0 0 10px #d5665b, 0 0 40px #d5665b, 0 0 80px #d5665b;
}



.speaker-con .speaker-con-btn a {
  color: #fff;
  font-size: 1.1rem;
  font-weight: medium;
  font-family: "Inter", sans-serif;
}

.speaker-con .speaker-con-btn .speaker-con-icon{
  display: flex;
  align-items: center;
}

.speaker-con-icon .MuiSvgIcon-root{
  height: 0.7em !important;
  font-size: 2rem !important;
  font-weight: 800;
}

.speaker-con .speaker-topic p {
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  padding: 0.3rem;
  margin: 0.5rem 0;
  word-wrap: break-word;
  border-top: none;
}
