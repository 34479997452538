body {
    background-color: #000;
    min-height: 100vh;
    width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  /* font-family: Hack,Courier,monospace; */
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  margin: 0;
  padding: 0;
  /* scrollbar-width: thin; */
  scroll-behavior: smooth;
  scrollbar-color: #d5665b;
  user-select: none;
}

html {
  font-size: 62.5%;
}
