.window-wc{
    height: 65%;
    margin-top: 8rem;
    width: 90%;
    max-width: 900px;
    border: 0.1rem solid #fff;
    color: #fff;
    background-color: rgba(25, 23, 23, 0.65);
    display: flex;
    justify-content: center;
    border-radius: 1rem;
}

.allcard{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:2rem;
    padding: 2rem;
}

.card-social {
    width: 10rem;
    height: 10rem;
    flex-grow: 0;
    flex-shrink: 0;
    border: 0.15rem solid #e56c61;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    transition: transform 0.1s linear;
    user-select: none;

}

.window-content-social{
    width: 100%;
}

.getin-cont{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 20%;
}

.getin-cont .header-logo {
    width: 20rem;
    object-fit: contain;
    cursor: pointer;
}

.getin-cont-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-block: 1.5rem;
}

.getin-cont-text p{
    display: flex;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
    font-size: 1.75rem;
}

.getin-cont-text a{
    text-decoration: none;
    color: #fff;
}

.getin-cont-text span{
    color: #e56c61;
}

@media screen and (max-width: 800px) {
    .window-wc{
        height: 82%;
        margin-top: 5rem;
    }
}

@media screen and (max-width: 500px) {
    .window-wc{
        height: 80%;
        margin-top: 5rem;
        overflow-y: auto;
    }

    .getin-cont-text p{
        font-size: 1.5rem;
    }

    .card-social{
        width: 8rem;
        height: 8rem;
        flex-grow: 0;
        flex-shrink: 0;
        border: 0.15rem solid #e56c61;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #fff;
        transition: transform 0.1s linear;
        user-select: none;   
    }
}